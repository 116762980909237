import React from 'react'
import logo from '../logo.svg'
// import { Link } from 'react-router-dom'

function Menu(props) {
  return (
    <div className="hb-header">
      <h1 className="hb-logo">
        <a className="hb-logo__link scroll" href="#site_content">
          <img src={logo} className="hb-logo__img" alt="HeyBoss" />
          <h1 className="hb-logo__text">HeyBoss</h1>
        </a>
        {/* <Link to="/" className="hb-logo__link">
          <img src={logo} className="hb-logo__img" alt="HeyBoss" />
          <h1 className="hb-logo__text">HeyBoss</h1>
        </Link> */}
      </h1>
      <div className={`hb-menu ${props.mobile}`}>
        {/* <Link to="/team" className="hb-menu__link">Team</Link> */}
        <a className="hb-menu__link scroll" href="#about_block">About</a>
        <a className="hb-menu__link scroll" href="#product_block">Product</a>
        <a className="hb-menu__link scroll" href="#reviews_block">Reviews</a>
        <a className="hb-menu__link scroll" href="#contact_block">Contact</a>
        <a className="btn btn--premium scroll" href="#download_block">Download Now</a>
      </div>
    </div>
  );
}

export default Menu

import React from 'react'
import Title from './Title'
import how001 from '../assets/img/how-001.svg'

function About() {
  return (
    <section id="about_block">
      <Title
        type="section-title__default"
        text="Best Solution for Remotely Workers"
      />
      <div className="container">
        <div className="about">
          <div className="about__list">
            <h4 className="about__subtitle right">Problems</h4>
            <ul className="about__list--problems">
              <li className="first active">Some bosses need to be constantly informed on what and when you are doing</li>
              <li className="second">If you want to go to the office you must let your boss know in advance to find out if there are available seats at the office</li>
              <li className="third">If you internet connection is acting up, or you left your working place</li>
              <li className="fourth">We make your work life stress-free, putting up your current status</li>
            </ul>
          </div>
          <div className="about__dots">
            <span className="about__dot about__dot--first active"></span>
            <span className="about__dot about__dot--second"></span>
            <span className="about__dot about__dot--third"></span>
            <span className="about__dot about__dot--fourth"></span>
          </div>
          <div className="about__list">
            <h4 className="about__subtitle">Solutions</h4>
            <ul className="about__list--solutions">
              <li className="first active">You just need to install the application and your boss will know where you are and what you are doing at the moment.</li>
              <li className="second">The app will automatically inform your boss about a changing your status</li>
              <li className="third">Your boss will be informed of exactly what happened</li>
              <li className="fourth">Your status will change to “I’m Busy Now” for a short time.</li>
            </ul>
          </div>
          <div className="about__block">
            <div>
              <h4 className="about__title">How it works</h4>
              <h6 className="about__title--sub">Status Changed</h6>
            </div>
            <p className="about__text">The app will automatically inform your boss about a changing your status</p>
            <p className="about__text">Your boss will be informed of exactly what happened</p>
            <img className="about__screen" src={how001} alt="screen 1" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default About
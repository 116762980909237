import React, { useState } from 'react'
import Menu from './Menu'

function Header() {
  const [show, setShow] = useState('')
  const [mobile, setMobile] = useState('')
  return (
    <section id="top_block">
      <div className="container">
        <div
          className={`hb-burger ${show}`}

          onClick={() => {
            show === 'opened' ? setShow('') : setShow('opened')
            mobile === 'mob-menu' ? setMobile('') : setMobile('mob-menu')
          }}>
          <span className="one"></span>
          <span className="two"></span>
          <span className="three"></span>
        </div>
        <Menu mobile={mobile} />
      </div>
    </section>
  );
}

export default Header

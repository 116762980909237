import React from 'react'
import diagram from '../assets/img/diagram.svg'

function Statistic() {
  return (
    <section id="statistic_block">
      <div className="container">
        <div className="statistic">
          <div className="statistic__left">
            <img src={diagram} alt="statistic" />
          </div>
          <div className="statistic__right">
            <div className="statistic__card">
              <h3 className="statistic__title">55%</h3>
              <p  className="statistic__text">we guarantee to increase your productivity</p>
            </div>
            <div className="statistic__card right">
              <h3 className="statistic__title">85%</h3>
              <p  className="statistic__text">saving time of communications </p>
            </div>
            <div className="statistic__card">
              <h3 className="statistic__title">2.3M</h3>
              <p  className="statistic__text">downloads in the last three months</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Statistic
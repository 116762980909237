import React from 'react'
import partner001 from '../assets/img/icons/partners/android.svg'
import partner002 from '../assets/img/icons/partners/apple.svg'
import partner003 from '../assets/img/icons/partners/windows.svg'
import partner004 from '../assets/img/icons/partners/github.svg'
import partner005 from '../assets/img/icons/partners/dropbox.svg'
import partner006 from '../assets/img/icons/partners/sandbox.svg'
import partner007 from '../assets/img/icons/partners/codepen.svg'
import partner008 from '../assets/img/icons/partners/sketch.svg'

function Footer() {
  return (
    <footer className="footer">
      <div className="container text-center">
        <div className="partners">
          <span className="partners__link">
            <img src={partner001} className="partners__image" alt="Android" />
          </span>
          <span className="partners__link">
            <img src={partner002} className="partners__image" alt="Apple" />
          </span>
          <span className="partners__link">
            <img src={partner003} className="partners__image" alt="Windows" />
          </span>
          <span className="partners__link">
            <img src={partner004} className="partners__image" alt="Github" />
          </span>
          <span className="partners__link">
            <img src={partner005} className="partners__image" alt="Dropbox" />
          </span>
          <span className="partners__link">
            <img src={partner006} className="partners__image" alt="Sandbox" />
          </span>
          <span className="partners__link">
            <img src={partner007} className="partners__image" alt="Codepen" />
          </span>
          <span className="partners__link">
            <img src={partner008} className="partners__image" alt="Sketch" />
          </span>
        </div>
        {/* <div className="partners">
          <a className="partners__link" href="/">
            <img src={partner001} className="partners__image" alt="Android" />
          </a>
          <a className="partners__link" href="/">
            <img src={partner002} className="partners__image" alt="Apple" />
          </a>
          <a className="partners__link" href="/">
            <img src={partner003} className="partners__image" alt="Windows" />
          </a>
          <a className="partners__link" href="/">
            <img src={partner004} className="partners__image" alt="Github" />
          </a>
          <a className="partners__link" href="/">
            <img src={partner005} className="partners__image" alt="Dropbox" />
          </a>
          <a className="partners__link" href="/">
            <img src={partner006} className="partners__image" alt="Sandbox" />
          </a>
          <a className="partners__link" href="/">
            <img src={partner007} className="partners__image" alt="Codepen" />
          </a>
          <a className="partners__link" href="/">
            <img src={partner008} className="partners__image" alt="Sketch" />
          </a>
        </div> */}
        {/* <div className="menu--footer">
          <a className="menu--footer__link active" href="/">Home</a>
          <a className="menu--footer__link" href="/privacy">Privacy Policy</a>
          <a className="menu--footer__link" href="/terms">Terms of Use</a>
          <a className="menu--footer__link" href="/support">Support</a>
        </div> */}
        <p className="menu--footer__text">HeyBoss - &copy;2021. All Rights Reserved</p>
      </div>
    </footer>
  );
}

export default Footer
import React from 'react'
import Header from './components/Header'
import Intro from './components/Intro'
import About from './components/About'
import Statistic from './components/Statistic'
import Cta from './components/Cta'
import Reviews from './components/Reviews'
import Download from './components/Download'
import Contact from './components/Contact'
import Footer from './components/Footer'
import './styles/custom.sass'

function App() {
  return (
    <>
      <Header />
      <Intro />
      <About />
      <Statistic />
      <Cta />
      <Reviews />
      <Download />
      <Contact />
      <Footer />
    </>
  );
}

export default App;
